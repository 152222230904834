<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        {{ $t('NOTIFICATIONS') }}
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch1"
          v-model="notificationData.isSendDailyEmail"
          name="check-button"
          switch
          inline
        >
          <span>{{ $t('DAILY_REMINDERS') }}</span>
        </b-form-checkbox>
      </b-col>
      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="onSubmit()"
        >
          {{ $t('BUTTON.SAVE_CHANGES') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mt-1"
          variant="outline-secondary"
        >
          {{ $t('BUTTON.RESET') }}
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
    saveOrUpdate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.notificationData)),
    }
  },
  methods: {
    onSubmit() {
      this.saveOrUpdate(this.notificationData)
    },
  },
}
</script>
